export const DEFAULT_LANG = 'en';
export const ALL_LANGS = ['en'] as const;
export const AUTHOR_NAME = 'The Salary Coach';
export const DEFAULT_CURRENT_YEAR = 2023;

export const DEFAULT_SITE_DOMAIN = 'personalityquiz.thesalarycoach.com.au';
export const DEFAULT_SITE_BASE_URL = 'https://personalityquiz.thesalarycoach.com.au/';

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;

export const MAX_OG_DESCRIPTION_LENGTH = 300;

type StringIteratee<EntryType> = string | ((arg0: EntryType) => string);
type GenericIteratee<EntryType, CompareType> = string | ((arg0: EntryType) => CompareType);

type SortOrder = 'asc' | 'desc';
type CompareFunction<CompareType> = (a: CompareType, b: CompareType) => number;

export function mapBy<EntryType>(
  array: Array<EntryType>,
  iteratee: StringIteratee<EntryType>,
): Partial<Record<string, EntryType>> {
  const result: Partial<Record<string, EntryType>> = {};
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as string);
    if (key in result) {
      throw new Error(
        `Found duplicate key ${key} for iteratee ${iteratee} on array ${JSON.stringify(array)}`,
      );
    }
    result[key] = value;
  }
  return result;
}

export function groupBy<EntryType>(
  array: Array<EntryType>,
  iteratee: StringIteratee<EntryType>,
): Record<string, Array<EntryType>> {
  const result: Record<string, Array<EntryType>> = {};
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as string);
    if (!(key in result)) {
      result[key] = [];
    }
    result[key].push(value);
  }
  return result;
}

function getComparisonScore<EntryType, CompareType>(
  a: EntryType,
  b: EntryType,
  iteratee: GenericIteratee<EntryType, CompareType>,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): number {
  const aValue =
    typeof iteratee === 'function' ? iteratee(a) : ((a as any)[iteratee] as CompareType);
  const bValue =
    typeof iteratee === 'function' ? iteratee(b) : ((b as any)[iteratee] as CompareType);
  if (typeof compareFuncOrOrder === 'function') {
    return compareFuncOrOrder(aValue, bValue);
  }

  const sortDirectionMul = compareFuncOrOrder === 'desc' ? -1 : 1;
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return sortDirectionMul * aValue.localeCompare(bValue);
  } else if (typeof aValue === 'number' && typeof bValue === 'number') {
    return sortDirectionMul * (aValue - bValue);
  } else if (aValue instanceof Date && bValue instanceof Date) {
    return sortDirectionMul * (aValue.getTime() - bValue.getTime());
  } else {
    throw new Error(
      'compareFuncOrOrder was not provided as a function but values to getComparisonScore are not string or numbers',
    );
  }
}

export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee: GenericIteratee<EntryType, CompareType>,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): Array<EntryType>;

export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  sortOptionsArray: Array<
    | GenericIteratee<EntryType, unknown>
    | readonly [GenericIteratee<EntryType, unknown>, SortOrder | CompareFunction<unknown>]
  >,
): Array<EntryType>;

export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee:
    | GenericIteratee<EntryType, CompareType>
    | Array<
        | GenericIteratee<EntryType, unknown>
        | readonly [GenericIteratee<EntryType, unknown>, SortOrder | CompareFunction<unknown>]
      >,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): Array<EntryType> {
  const sortOptionsArray = Array.isArray(iteratee)
    ? iteratee
    : [[iteratee, compareFuncOrOrder] as const];
  return [...array].sort((a, b) => {
    for (const sortOptions of sortOptionsArray) {
      const [iteratee, compareFuncOrOrder] = Array.isArray(sortOptions)
        ? sortOptions
        : [sortOptions];
      const comparisonScore = getComparisonScore(a, b, iteratee, compareFuncOrOrder);
      if (comparisonScore !== 0) {
        return comparisonScore;
      }
    }
    return 0;
  });
}

export function flatten<EntryType>(arrayOfArrays: Array<Array<EntryType>>): Array<EntryType> {
  return arrayOfArrays.reduce((acc, val) => acc.concat(val), []);
}

export function uniqBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee: GenericIteratee<EntryType, CompareType>,
): Array<EntryType> {
  const set = new Set();
  const uniqArray = [];
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as CompareType);
    if (!set.has(key)) {
      set.add(key);
      uniqArray.push(value);
    }
  }
  return uniqArray;
}

export function uniq<EntryType>(array: Array<EntryType>): Array<EntryType> {
  return [...new Set(array)];
}

export function range(start: number, end?: number): Array<number> {
  if (end === undefined) {
    end = start;
    start = 0;
  }
  const result = [];
  if (end < start) {
    for (let n = start; n > end; n--) {
      result.push(n);
    }
  } else {
    for (let n = start; n < end; n++) {
      result.push(n);
    }
  }
  return result;
}

export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  const arrCopy = [...arr];
  if (index > -1) {
    arrCopy.splice(index, 1);
  }
  return arrCopy;
}

export function shuffle<T>(array: Array<T>): Array<T> {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

export function arrayEquals(a: Array<unknown>, b: Array<unknown>): boolean {
  return a.length === b.length && a.every((val, index) => val === b[index]);
}

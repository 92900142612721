import { Link } from 'gatsby';
import React from 'react';
import ScheduleIcon from '../../images/scheduleIcon.inline.svg';
import { useGlobalState } from '../../state/globalStateContext';
import Button from './Button';

import * as styles from './Header.module.scss';

interface HeaderProps {
  bookACallLink: string;
  backgroundColor: 'gradient' | 'white';
  logoUrl: string;
  logoIconUrl: string;
}

const Header = ({
  bookACallLink,
  backgroundColor,
  logoUrl,
  logoIconUrl,
}: HeaderProps): React.ReactElement => {
  const { resetState } = useGlobalState();
  return (
    <header
      className={styles.header}
      style={{
        background: backgroundColor === 'gradient' ? 'transparent' : '#ffffff',
      }}
    >
      <div className={styles.headerContainer}>
        <Link to="/" onClick={() => resetState()} className={styles.logoContainer}>
          <img src={logoIconUrl} className={styles.logoMobile} alt="" />
          <img src={logoUrl} className={styles.logoDesktop} alt="" />
        </Link>
        <a
          href={bookACallLink}
          target="_blank"
          rel="noreferrer"
          className={styles.button}
          tabIndex={-1}
        >
          <Button secondary>
            Book a call
            <ScheduleIcon className={styles.icon} />
          </Button>
        </a>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './Button.module.scss';

export interface ButtonProps {
  secondary?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const Button = ({ secondary, children, className, onClick }: ButtonProps): React.ReactElement => {
  return (
    <button
      className={clsx(className, styles.container, secondary && styles.secondary)}
      onClick={onClick}
    >
      <span className={styles.children}>{children}</span>
    </button>
  );
};

export default Button;

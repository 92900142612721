import React, { useEffect, useState } from 'react';
import { DEFAULT_CURRENT_YEAR } from '../../constants';

import * as styles from './Footer.module.scss';

interface FooterProps {
  logoUrl: string;
}

const Footer = ({ logoUrl }: FooterProps): React.ReactElement => {
  const [currentYear, setCurrentYear] = useState<number>(DEFAULT_CURRENT_YEAR);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.logoContainer}>
          <a
            href="https://thesalarycoach.com.au/"
            target="_blank"
            rel="noopener"
            referrerPolicy="unsafe-url"
          >
            <img src={logoUrl} className={styles.logo} alt="The Salary Coach logo" />
          </a>
        </div>
        <div className={styles.copyrightContainer}>
          <span className={styles.span}>© {currentYear} The Salary Coach</span>
          <span className={styles.span}>All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import Footer from './Footer';
import Header from './Header';
import * as styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
  headerBookACallLink: string;
  hideLayoutHeader?: boolean;
  headerBackgroundColor: 'gradient' | 'white';
  logoUrl: string;
  logoIconUrl: string;
}

const Layout = ({
  headerBookACallLink,
  hideLayoutHeader,
  headerBackgroundColor,
  children,
  logoUrl,
  logoIconUrl,
}: LayoutProps): React.ReactElement => {
  return (
    <main className={styles.main}>
      {!hideLayoutHeader && (
        <Header
          backgroundColor={headerBackgroundColor}
          bookACallLink={headerBookACallLink}
          logoUrl={logoUrl}
          logoIconUrl={logoIconUrl}
        ></Header>
      )}
      <div className={styles.children}>{children}</div>
      <Footer logoUrl={logoUrl}></Footer>
    </main>
  );
};

export default Layout;
